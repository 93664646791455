<template>
    <div class="cubigbox">
        <div class="header" id="header">
            <!-- <img src="../../assets/img/ywllogo.png" alt="" class="logo"> -->
            <div class="headericon" id="headericon" @click="headericon"></div>
        </div>
        <div class="headerhidden" id="headerhidden">
            <div class="headeritem" @click="toindex"> <span> 首页 </span> </div>
            <div class="headeritem" @click="toaboutus"> <span>关于我们 </span> </div>
            <div class="headeritem" @click="tomovielist"> <span>影片推荐</span> </div>
            <div class="headeritem" @click="tonews"> <span>新闻资讯</span> <div id="headjt" v-on:click.stop="headjt"></div> </div>
            <div id="hiddenbox">
                <div class="headeritem headeritem2" @click="togsdt"> <span >公司动态</span> </div>
                <div class="headeritem headeritem2" @click="toyszx"> <span >影视资讯</span> </div>
                <div class="headeritem headeritem2" @click="tohyfx"> <span >行业分析</span> </div>
                <div class="headeritem headeritem2" @click="tozcjd"> <span >政策解读</span> </div>
            </div>
            
            <!-- <div class="headeritem" @click="tongyhd"> <span>社会责任</span> </div>
            <div class="headeritem" @click="touserguide"> <span>用户指南</span> </div> -->
            <div class="headeritem lastitem" @click="tocu"> <span>联系我们</span> </div>
        </div>
        <div style="height: 1.08rem;"></div>

        <div class="cubox">
            <img src="../../assets/img/bg.svg" alt="" class="cuboxbg">
            <div class="cubox1">
                <!-- <img src="../../assets/img/ywllogo.png" alt="" class="cuboxlogo"> -->
                <div class="cubox2" v-if="phoneNum">
                    <span>湖北省武汉市洪山区珞狮南路397号中影文化金融中心</span>
                    <span>公司邮箱：ywlwh1029@163.com</span>
                    <span>联系电话：{{phoneNum}}</span>
                    <!-- <div class="cuboxewmbox">
                        <img src="../../assets/img/ewm2.svg" alt="">
                    </div> -->
                </div>
                
            </div>
            
        </div>


    </div>
</template>


<script>
import {listPhoneStaff} from "@/api/index"
export default {
    data(){
        return {
            t : null,
            headerflag : false,
            hiddenflag : false,
            phoneNum : null,
        }
    },
    methods:{
        toindex(){
            this.reset()
            this.$router.push({name:'index'});
        },
        toaboutus(){
            this.reset()
            this.$router.push({name:'aboutus'});
        },
        tonews(){
            this.reset()
            this.$router.push({name:'news'});
        },
        togsdt(){
            this.reset()
            this.$router.push({name:'gsdt'});
        },
        toyszx(){
            this.reset()
            this.$router.push({name:'yszx'});
        },
        tohyfx(){
            this.reset()
            this.$router.push({name:'hyfx'});
        },
        tozcjd(){
            this.reset()
            this.$router.push({name:'zcjd'});
        },
        tomovielist(){
            this.reset()
            this.$router.push({name:'movielist'});
        },
        tongyhd(){
            this.reset()
            this.$router.push({name:'gyhd'});
        },
        touserguide(){
            this.reset()
            this.$router.push({name:'userGuide'});
        },
        tocu(){
            this.reset()
            this.$router.push({name:'connectus'});
        },
        headericon(){
            var headericon = document.getElementById("headericon")
            var headerhidden = document.getElementById("headerhidden")
            if(this.headerflag){
                headerhidden.style.top = "-100%"
                headericon.classList.remove("headericon2")
                headericon.classList.add("headericon")
                this.headerflag = false
            }else{
                headerhidden.style.top = "1.06rem"
                headericon.classList.remove("headericon")
                headericon.classList.add("headericon2")
                this.headerflag = true
            }
        },
        headjt(){
            var headjt = document.getElementById("headjt")
            var hiddenbox = document.getElementById("hiddenbox")
            if(this.hiddenflag){
                hiddenbox.style.height = "0"
                headjt.style.transform = "rotateZ(0deg)"
                this.hiddenflag = false
            }else{
                hiddenbox.style.height = "4rem"
                headjt.style.transform = "rotateZ(-180deg)"
                this.hiddenflag = true
            }
        },
        reset(){
            this.headerflag = false
            this.hiddenflag = false
            var headericon = document.getElementById("headericon")
            var headerhidden = document.getElementById("headerhidden")
            var headjt = document.getElementById("headjt")
            var hiddenbox = document.getElementById("hiddenbox")
            headerhidden.style.top = "-100%"
            headericon.classList.remove("headericon2")
            headericon.classList.add("headericon")
            hiddenbox.style.height = "0"
            headjt.style.transform = "rotateZ(0deg)"
        }
    },
    mounted(){
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        
        listPhoneStaff({
            "data": {
                "isShow": "1"
            },
            "mapParam": {}
        }).then(res => {
            //console.log(res[0].phoneNum)
            this.phoneNum = res[0].phoneNum
        })

    }
}
</script>


<style lang="scss" scoped>
.cubigbox{
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.cubox{
    height: calc(100% - 1.08rem);
    width: 100%;
    position: relative;
    overflow: hidden;
    font-size: 0.24rem;
    font-weight: 400;
    line-height: 0.28rem;
    color: #7D7D7D;
    background: #FFFFFF;
}
.cuboxbg{
    height: 100%;
    position: absolute;
    z-index: 99;
}
.cuboxlogo{
    width: 4.66rem;
    height: 1.28rem;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
.cubox1{
    width: 100%;
    height: 100%;
    z-index: 100;
    position: relative;
}
.footbox2{
    width: 100%;
    position: absolute;
    bottom: 0.2rem;
}
.cubox2{
    width: 100%;
    position: absolute;
    bottom: 35%;
}
.cubox2 span{
    display: block;
    width: 100%;
    text-align: center;
    -webkit-transform:scale(0.92);
}
.cubox2 p{
    text-align: center;
}
.cubox .cuboxewmbox{
    width: 1.8rem;
    height: 2.02rem;
    position: relative;
    margin: 0 auto;
    margin-bottom: 0.56rem;
    margin-top: 0.08rem;
}
.cubox .cuboxewmbox img{
    width: 1.8rem;
    height: 2.02rem;
}
</style>